.App {
  height: 100vh;
  display:flex;
  flex-direction: column;
  text-align: center;
  background-color: #4A5D5E; 
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  
}

button{
  padding: 0.25rem;
  border: 1px solid black;
  font-size: 0.5rem;
  border-radius: 2px;
  background-color: #D2DBC8;
  
}
a{
  color: white;
}
p{
  color: #F8F8EC;
  font-style: italic;
  font-size: 0.75rem;
  max-width: 50vw;

}